import React, { useEffect } from 'react'
import Select from 'react-select'
import { useHeadquarter } from '../../hooks/useHeadquarter'
import { useValidationForm } from '../../hooks/useValidationForm'

import { SelectWrapper } from './MySelect.style'

export const MySelect = ({
    item,
    options,
    placeholder,
    inputRef,
    defaultValue,
    isMulti,
    isSearchable,
    form,
    setInputValue,
    setErrorInput,
    setValidationNormalInput,
    validationNormalInput,
    allSteps,
    idPage,
    destinationOpen,
    isDisabled = false,
    setValidateReducer,
    reducerForm,
    firstData,
    value,
}) => {
    const { getHeadquarter, companyLink } = useHeadquarter()
    const { validationForm } = useValidationForm()

    useEffect(() => {
        getHeadquarter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectChange = (inputValue, action, item) => {
        if (reducerForm !== undefined) {
            item.idDestination = destinationOpen === undefined ? null : destinationOpen
            item.idPage = idPage
            item.action = action.action

            let responseReducerValidate = validationForm(reducerForm, firstData, item)
            setValidateReducer(responseReducerValidate)
        }

        if (item.type === 'dynamic') {
            const associatedDataInputs = allSteps.reduce((inputs, step) => [...inputs, ...step.input.filter((input) => input.ref && input.ref.type === 'associatedData')], [])

            const departments = companyLink.filter((item) => item.Company.id === +inputValue?.value).map((item) => item.Department)

            associatedDataInputs.forEach((input) => {
                input.options.splice(0, input.options.length)
                input.options = departments.map((item) => ({
                    value: item.id,
                    label: item.name,
                }))
            })
        }

        if (inputValue?.length > 0) {
            setInputValue((prevState) => ({
                ...prevState,
                [item.element]: inputValue[0]?.label,
            }))
        } else {
            setInputValue((prevState) => ({
                ...prevState,
                [item.element]: inputValue?.label,
            }))
        }

        if (inputValue?.label === '' || inputValue?.length === 0 || inputValue === null) {
            setErrorInput(true)
            setValidationNormalInput((prevState) => [...prevState, item.element])
        }
        if (inputValue?.length === 0) {
            setInputValue((prevState) => ({
                ...prevState,
                [item.element]: '',
            }))
        } else {
            setValidationNormalInput((prevState) => prevState.filter((err) => err !== item.element))
            if (validationNormalInput.length === 0) setErrorInput(false)
        }

        if (inputValue) {
            inputValue.optionRefPage = idPage
            inputValue.destinationOpen = destinationOpen
        }

        if (action.removedValues) {
            action.removedValues[0].optionRefPage = idPage
            action.removedValues[0].destinationOpen = destinationOpen
        }

        form(action, item, inputValue)
    }

    return (
        <SelectWrapper>
            {Object.keys(defaultValue).length > 0 ? (
                value ? (
                    <Select
                        options={options}
                        placeholder={placeholder}
                        onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
                        inputRef={inputRef}
                        value={defaultValue}
                        isMulti={isMulti}
                        isSearchable={isSearchable}
                        isClearable={true}
                        isDisabled={isDisabled}
                    />
                ) : (
                    <Select
                        options={options}
                        placeholder={placeholder}
                        onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
                        inputRef={inputRef}
                        defaultValue={defaultValue}
                        isMulti={isMulti}
                        isSearchable={isSearchable}
                        isClearable={true}
                        isDisabled={isDisabled}
                    />
                )
            ) : (
                <Select
                    options={options[0]?.name ? options.map((option) => ({ name: option.name, label: option.label + ' - ' + option.name, value: option.value })) : options}
                    placeholder={placeholder}
                    onChange={(inputValue, action) => handleSelectChange(inputValue, action, item.ref, item.cardType)}
                    inputRef={inputRef}
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    isClearable={true}
                />
            )}
        </SelectWrapper>
    )
}

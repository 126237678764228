import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LineCharts from '../../../components/Graphs/Charts'
import LoaderSpinner from '../../../components/LoaderSpinner/LoaderSpinner'
import { NavButtons } from '../../../components/NavButtons/NavButtons'
import { TableInfo } from '../../../components/TableInfo/TableInfo'
import { dataScreenHistory } from '../../../data/dataEM'
import useEM from '../../../hooks/useEM'
import { NotFoundScreen } from '../../NotFoundScreen/NotFoundScreen'

import { HistoryWrapper } from './HistoryScreen.style'
import useApplications from '../../../hooks/useApplications'

export const HistoryScreen = () => {
    const { histories, getHistories, loading } = useEM()
    const { arrayEMAlerts, getEMAlerts } = useEM()
    const { audios } = useApplications()

    const filesMP3 = audios.audio

    const { notFound } = dataScreenHistory

    const navigate = useNavigate()

    const [search, setSearch] = useState([])
    const [, setIsBottom] = useState(false)

    useEffect(() => {
        getHistories()
        getEMAlerts()
        setSearch([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSearch])

    function foundFile(filename) {
        let file
        if (filesMP3.find((file) => file.includes(filename))) {
            file = filename
        }
        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
            return process.env.REACT_APP_URL.replace('api', 'uploads') + '/mp3/' + file
        } else {
            return process.env.REACT_APP_UPLOAD_FOLDER + '/mp3/' + file
        }
    }

    const groupHistories = (items) => {
        const userDefType = []
        items.map((history) => {
            const { userdeftype } = history
            if (userdeftype) {
                userDefType.push(history)
            }
        })
        userDefType.map((item) => {
            let substrings = item.appdata.split(',')
            let src = substrings.find((substring) => substring.includes('em_uid'))
            if (src) {
                let srcValue = src.substring(src.indexOf(':em-') + 1)
                item.em_uid = srcValue
            } else {
                console.log('No hay datos')
            }
        })

        const group = userDefType.reduce((ac, obj) => {
            if (!ac[obj.em_uid]) {
                ac[obj.em_uid] = []
            }
            ac[obj.em_uid].push(obj)
            return ac
        }, {})

        const arrayGroup = Object.entries(group).map(([key, value]) => {
            return {
                em_uid: key,
                objects: value,
            }
        })

        return arrayGroup
    }

    const userDefType = groupHistories(histories)

    const COLUMNS = [
        {
            Header: 'ID alerta',
            accessor: 'linkedid',
        },
        {
            Header: 'Lista de difusión',
            accessor: 'listDiffusion',
        },
        {
            Header: 'Locución escuchada',
            accessor: 'soundPrompt',
            Cell: ({ row }) => (
                <div>
                    <audio style={{ width: '190px', height: '35px' }} src={row.original.filepath} controls />
                </div>
            ),
        },
        {
            Header: 'Extensión emisora',
            accessor: 'issuingExtension',
        },
        {
            Header: 'Hora de emisión',
            accessor: 'eventtime',
            Cell: ({ row }) => <div dangerouslySetInnerHTML={{ __html: row.original.eventtime }} />,
        },
    ]

    function dataInsideRow(row) {
        navigate('/EM/history/item', {
            state: row.original ? row.original : row,
        })
    }

    function foundData(data, search) {
        let substrings = data.split(',')
        let src = substrings.find((substring) => substring.includes(search))
        if (src) {
            let srcValue = src.substring(src.indexOf(':') + 1)
            return srcValue
        } else {
            return 'No hay datos'
        }
    }

    function foundTime(date) {
        let time = moment(date).format('YYYY-MM-DD <br/> HH:mm:ss')
        return time
    }

    function foundAlert(data, search, type) {
        let codeAlert = foundData(data, search)
        let newName
        if (type === 'list') {
            arrayEMAlerts.forEach((item) => {
                if (item.keyCall === codeAlert) {
                    newName = item.principalList.name
                }
            })
        } else if (type === 'sound') {
            arrayEMAlerts.forEach((item) => {
                if (item.keyCall === codeAlert) {
                    newName = item.Soundprompt.filename
                }
            })
        }
        return newName
    }

    const DATA = userDefType.reverse().map((item) => ({
        itemsSameLinkedId: item.objects,
        linkedid: item.objects[0].linkedid,
        listDiffusion: foundAlert(item.objects[0].appdata, 'emcode:', 'list'),
        soundPrompt: foundFile(foundAlert(item.objects[0].appdata, 'emcode:', 'sound')),
        issuingExtension: foundData(item.objects[0].appdata, 'src:'),
        eventtime: foundTime(item.objects[0].eventtime),
    }))

    const tableInstance = useRef(null)

    let groupData = DATA.reduce(function (acc, history) {
        let date = history.eventtime.match(/^\d{4}-\d{2}-\d{2}/)[0] // Extraer la date
        if (!acc[date]) {
            acc[date] = []
        }
        acc[date].push(history)
        return acc
    }, {})

    for (let date in groupData) {
        let dayName = new Date(date).toLocaleString('es', { weekday: 'long' })
        groupData[dayName] = groupData[date]
        delete groupData[date]
    }

    const dataKeys = [{ dataKey: 'salientes', color: '#ED7E63' }]

    const dataLine = [
        { name: 'lunes', salientes: 0 },
        { name: 'martes', salientes: 0 },
        { name: 'miércoles', salientes: 0 },
        { name: 'jueves', salientes: 0 },
        { name: 'viernes', salientes: 0 },
        { name: 'sábado', salientes: 0 },
        { name: 'domingo', salientes: 0 },
    ]

    for (let i in dataLine) {
        for (let date in groupData) {
            if (dataLine[i].name === date) {
                dataLine[i].salientes = groupData[date].length
            }
        }
    }

    return (
        <HistoryWrapper>
            <div className="contain__buttons">
                <NavButtons
                    optionButtons={{
                        search: {
                            position: 'left',
                            data: DATA,
                            columns: COLUMNS,
                        },
                        // file: {
                        //     position: 'right',
                        //      data: dataExport,
                        //     // headers: headersExport,
                        //     import: false,
                        // },
                    }}
                    setSearch={setSearch}
                />
            </div>

            {loading ? (
                <LoaderSpinner />
            ) : histories[0]?.status ? (
                <div className="contain__notFound">
                    <NotFoundScreen icon={notFound.icon} title={notFound.title} subtitle={notFound.subtitle} textButton={notFound.textButton} />
                </div>
            ) : search[0]?.notFound ? (
                <div className="contain__notFound">
                    <h4>{search[0].notFound}</h4>
                </div>
            ) : (
                <div className="contain__table-statistics">
                    <div className="contain__statistics">
                        <TableInfo
                            format={'list'}
                            COLUMNS={COLUMNS}
                            DATA={search.length === 0 ? DATA : search}
                            deleteFunction={null}
                            updateFunction={null}
                            isOptions={false}
                            cursorPointer={true}
                            onClick={dataInsideRow}
                            ref={tableInstance}
                            setIsBottom={setIsBottom}
                            isCheckbox={false}
                        />
                    </div>

                    <div className="stadistics_charts">
                        <div className="graph border">
                            <LineCharts data={dataLine} dataKeys={dataKeys} />
                        </div>
                        {/* <div className="graph border">
                            <BarCharts data={dataBar} />
                        </div> */}
                    </div>
                </div>
            )}
        </HistoryWrapper>
    )
}

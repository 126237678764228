import styled from 'styled-components'

export const HistoryToLinkedeIdWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;

    .contain__table {
        box-sizing: border-box;
        height: calc(100% - 55px);
        transition: all 1s easy-out;
    }
    .contain__cardGrid {
        height: calc(100% - 55px);
        box-sizing: border-box;
        padding: 20px 36px 0;
        transition: all 1s easy-out;
    }

    .contain__notFound {
        height: calc(100% - 55px);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contain__table-statistics {
        height: calc(100% - 55px);
        box-sizing: border-box;
        border-top: 1px solid #d2d3d4;
        overflow: hidden;
        display: grid;
        grid-template-columns: calc(100% - 350px) 350px;

        .contain__statistics {
            height: 100%;
            overflow: scroll;
            div::-webkit-scrollbar {
                display: none;
            }
        }
        .boxStatistic {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding: 20px 10px;
            gap: 5px;

            .box {
                width: 80%;
                height: 154px;
            }
        }
    }
`
